import React, { useState, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import { useRWD } from 'hooks';
import { useGetHomeSubelementsTypes, useGetHomeSubelementsInfiniteList } from 'api';
import { IProductListItem } from 'api/types';
import { HidePrices, ProductItem, ProductSlider } from 'components/containers';
import { Container, Loader } from 'components/controls';

import styles from 'theme/pages/Home/components/Tabs/Tabs.module.scss';

const HomeSubelements = ({ isSlider }: { isSlider?: boolean }) => {
  // Aktualnie wybrany typ subelementu
  const [activeSubelementId, setActiveSubelementId] = useState<string | null>(null);

  // referencja dla komponenyu paginacji
  const paginationRef = useRef<HTMLInputElement>(null);

  const { isMobile } = useRWD();

  const [productsQuery, setProductsQuery] = useState({
    page: 1,
    limit: 24
  });

  // ustawienie flagi czy należy wykonać zapytanie o kolejną stronę
  const [isPaginationOnScreen, setIsPaginationOnScreen] = useState(false);

  // Pobranie typów subelementów
  const { data: homeSubelementsTypesData } = useGetHomeSubelementsTypes(
    {
      page: 1,
      limit: 24
    },
    {
      onSuccess: (data) => {
        // Ustawienie pierwszego typ subelementu jako wybrany
        setActiveSubelementId(data.items[0]?.id || null);
      }
    }
  );

  // Pobranie listy produktów po zmianie/wybraniu typu subelementu
  const {
    data: homeSubelementItemsData,
    refetch: refetchHomeSubElementsData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isHomeSubelementItemsLoading
  } = useGetHomeSubelementsInfiniteList(activeSubelementId || '', productsQuery, {
    enabled: !!activeSubelementId,
    keepPreviousData: true,
    cacheTime: 1
  });

  // Przygotowanie listy produktów do wyświetlenia
  const itemsList = useMemo(() => {
    const list: IProductListItem[] = [];

    homeSubelementItemsData?.pages.forEach((page) => {
      list.push(...page.items.map((item) => ({ ...item, page: page.page })));
    });

    return list;
  }, [homeSubelementItemsData, productsQuery]);

  // liczba produktów aktualnie na stronie
  const itemsCount: number = itemsList.length;

  // 'offset' dla doładowania kolejnych produktów
  const productsOffset = 1000;

  // całkowita liczba produktów
  const totalCount: number = homeSubelementItemsData
    ? homeSubelementItemsData.pages[homeSubelementItemsData.pages.length - 1].total_count
    : 0;

  const hasNextPage = itemsCount < totalCount;

  useEffect(() => {
    !!activeSubelementId && refetchHomeSubElementsData();
  }, [productsQuery]);

  // obsługa scrolla
  useEffect(() => {
    const handleScroll = () => {
      const { current } = paginationRef;
      if (current) {
        const { y } = current.getBoundingClientRect();
        if (y - productsOffset < 0 && !isPaginationOnScreen) {
          setIsPaginationOnScreen(true);
        }
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.addEventListener('scroll', handleScroll);
  }, []);

  // pobieranie nowych pozycji po scrollu
  useEffect(() => {
    if (isPaginationOnScreen && hasNextPage) {
      fetchNextPage().then(() => {
        setIsPaginationOnScreen(false);
      });
    }
  }, [isPaginationOnScreen, hasNextPage, itemsCount]);

  const renderContent = () => {
    if (isSlider) {
      return <ProductSlider products={itemsList || []} itemsPerSlide={isMobile ? 1 : 6} />;
    }

    return (
      <div>
        <Grid
          container
          columnSpacing="16px"
          rowSpacing="20px"
          itemScope
          itemType="http://schema.org/ItemList">
          {itemsList.map((product) => (
            <Grid
              key={product.id}
              item
              md={6}
              lg={4}
              xl={3}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem">
              <ProductItem product={product} hidePricesEnabled showProductPreview />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home-components-Tabs')}>
      {homeSubelementsTypesData && (
        <div className={styles.productTabsWrapper}>
          <div className={styles.tabsBar}>
            <div className={styles.tabs}>
              {homeSubelementsTypesData.items.map((item) => (
                <div
                  key={item.id}
                  className={classnames(styles.tab, {
                    [styles.active]: item.id === activeSubelementId
                  })}
                  onClick={() => {
                    setActiveSubelementId(item.id);
                    setProductsQuery({ ...productsQuery, page: 1 });
                  }}>
                  {item.name}
                </div>
              ))}
            </div>

            <HidePrices />
          </div>
          <Container>
            <div className={styles.sliderWrapper}>
              {isHomeSubelementItemsLoading && <Loader />}
              {renderContent()}
              <div ref={paginationRef} className={classnames(styles.paginationWrapper)}>
                {isFetchingNextPage && <Loader />}
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default HomeSubelements;
