// baner ze sliderem, odliczaniem i zajawkami aktualności

import React from 'react';
import { Grid } from '@mui/material';
import { CarouselProvider, Slide, Slider as CarouselSlider } from 'pure-react-carousel';
import qs from 'query-string';
import classnames from 'classnames';

import { useGetHomeSlider } from 'api';
import { Link } from 'components/controls';

import styles from 'theme/pages/Home/components/Banner/Banner.module.scss';

export const Slider = () => {
  // pobranie danych slidera
  const { data: homeSliderData } = useGetHomeSlider();

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home-components-Banner')}>
      <Grid container>
        <Grid item xs={12}>
          {homeSliderData && (
            <div className={styles.slider}>
              <CarouselProvider
                naturalSlideWidth={0}
                naturalSlideHeight={0}
                totalSlides={homeSliderData?.items.length || 0}
                visibleSlides={1}
                dragEnabled={homeSliderData.items.length !== 1}
                isPlaying>
                <CarouselSlider>
                  {(homeSliderData?.items || []).map((slideData, index) => (
                    <Slide key={slideData.id} index={index} className={styles.slide}>
                      {slideData.url ? (
                        <a
                          href={slideData.url}
                          target={slideData.new_tab ? '_blank' : '_self'}
                          className={styles.slideLink}
                          rel="noreferrer">
                          <img src={slideData.image} />
                        </a>
                      ) : (
                        <Link
                          to={`/products?${qs.stringify(
                            {
                              category_id: slideData.category_id,
                              search_keyword: slideData.search_keyword,
                              sort_method: slideData.sort_method
                            },
                            { skipNull: true }
                          )}`}
                          target={slideData.new_tab ? '_blank' : '_self'}
                          className={styles.slideLink}>
                          <img src={slideData.image} />
                        </Link>
                      )}
                    </Slide>
                  ))}
                </CarouselSlider>
              </CarouselProvider>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Slider;
