// Hook odpowiedzialny za pobranie informacji o dostawie w koszyku

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IPaginationResponse } from 'api/types';

// typ zwracanych danych
export interface ICartAdditionalInformationListItem {
  type: string;
  style: 'info' | 'error';
  link: string;
  message_html: string;
}

// typ zwracanych danych
type IResponse = IPaginationResponse<ICartAdditionalInformationListItem>;

const getCartAdditionalInformation = (id: number): Promise<IResponse> =>
  axios.get(`/carts/${id}/additional-information`);

export const useGetCartAdditionalInformation = (
  id: number,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['cart-additional-information', id],
    () => getCartAdditionalInformation(id),
    options
  );
