// zwijany box

import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Container } from 'components/controls';

import { AngleDownIcon } from 'assets/icons';

import styles from 'theme/components/controls/Collapse/Collapse.module.scss';

// typ danych wejściowych
interface IProps {
  open: boolean;
  title: string;
  onClick?: () => void;
  isFilter?: boolean;
}
const Collapse: FC<PropsWithChildren<IProps>> = ({ title, children, open, onClick, isFilter }) => {
  const renderContent = () => {
    if (isFilter) {
      return (
        <>
          <div className={styles.header} onClick={onClick}>
            <div className={styles.box}>
              <div className={styles.title}>{title}</div>
              <div className={styles.arrow}>
                <AngleDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
              </div>
            </div>
          </div>
          {open && <div className={styles.content}>{children}</div>}
        </>
      );
    }

    return (
      <>
        <div className={styles.header} onClick={onClick}>
          <Container>
            <div className={styles.box}>
              <div className={styles.arrow}>
                <AngleDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
              </div>
              <div className={styles.title}>{title}</div>
            </div>
          </Container>
        </div>
        <Container>{open && <div className={styles.content}>{children}</div>}</Container>
      </>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.opened]: open, [styles.isFilter]: isFilter },
        'StylePath-Components-Controls-Collapse'
      )}>
      {renderContent()}
    </div>
  );
};

export default Collapse;
