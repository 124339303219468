import React, { useState } from 'react';
import classnames from 'classnames';
import { JournalText, ThreeDotsVertical, Trash } from 'react-bootstrap-icons';
import { Trans } from 'react-i18next';

import { Availability, DropDown, Label, Loader } from 'components/controls';
import { ICartPositionListItem, IProductMainAttribute } from 'api/types';
import { useGetProduct } from 'api';

import QuantityWrapper from './QuantityWrapper';

import styles from 'theme/components/containers/CartPositions/components/MobileCart.module.scss';
import AddToShoppingListButton from 'components/containers/AddToShoppingListButton';

interface IFeatures {
  setItemsToShoppingList: (items: ICartPositionListItem[]) => void;
  setItemsToRemove: (items: ICartPositionListItem[]) => void;
  updatingPositionIds: number[];
  handleChangeUnit: (unitId: number, position: ICartPositionListItem) => Promise<void>;
}

type Props = {
  item: ICartPositionListItem;
  features: IFeatures;
  cartId: number;
  onChange?: () => void;
  queryParams: object;
};

const MobileCart = ({
  item,
  cartId,
  onChange,
  queryParams,
  features: { setItemsToRemove }
}: Props) => {
  // Pobranie produktu
  const { data: productData, isLoading: isProductLoading } = useGetProduct(item.product_id, {
    enabled: !!item.product_id
  });

  const [openModal, setOpenModal] = useState(false);

  const renderMainAttribute = (attribute: IProductMainAttribute, i: number) => {
    return (
      <li className={styles.listItem} key={i}>
        {attribute.name}: <strong>{attribute.value}</strong>&nbsp;
      </li>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-CartPositions-Components-MobileCart'
      )}>
      {isProductLoading && <Loader />}
      <div className={styles.topBar}>
        <div className={styles.labels}>
          {productData?.labels.map((label) => (
            <Label key={label.type} label={label} />
          ))}
        </div>
        <DropDown
          label={<ThreeDotsVertical />}
          items={[
            {
              label: (
                <div
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className={classnames(styles.dropdownAction, styles.cart)}>
                  <JournalText />
                  <Trans>Dodaj do listy</Trans>
                </div>
              )
            },
            {
              label: (
                <div
                  onClick={() => setItemsToRemove([item])}
                  className={classnames(styles.dropdownAction, styles.bin)}>
                  <Trash /> <Trans>Usuń z koszyka</Trans>{' '}
                </div>
              )
            }
          ]}
          withDropdownIcon={false}
        />
      </div>
      <div className={styles.productInfo}>
        <div className={styles.title}>{item.name}</div>
        <ul className={styles.list}>
          {item.main_attributes.map((attribute, i) => renderMainAttribute(attribute, i))}
        </ul>
      </div>
      <div className={styles.options}>
        <span>
          {productData?.stock && (
            <Availability stock={{ name: item.warehouse_name, type: 'middle-stock', value: 0 }} />
          )}
        </span>
      </div>
      <div className={styles.productContent}>
        <img src={item.image[0]?.thumb} />

        <div className={styles.priceWrapper}>
          <div>
            <div className={styles.netto}>
              <span>
                {productData?.units[0].old_price_net && (
                  <>
                    {productData?.units[0].old_price_net_formatted} {item.currency}
                  </>
                )}
              </span>
              <span>
                {productData?.units[0].price_net_formatted} {item.currency}
              </span>
              <span>
                <Trans>netto</Trans>
              </span>
            </div>

            <div className={styles.brutto}>
              <span>
                {productData?.units[0].price_gross_formatted} {item.currency}
              </span>
              <span>
                <Trans>brutto</Trans>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.productOrder}>
        <QuantityWrapper
          cartId={cartId}
          item={item}
          onChange={onChange}
          queryParams={queryParams}
        />
      </div>
    </div>
  );
};

export default MobileCart;
