// dodatkowe filtry pod listą kategorii

import React, { FC } from 'react';

import { Grid } from '@mui/material';

import { Link } from 'components/controls';
import { ICategorySubcategoryListItem } from 'api/types';

import styles from 'theme/pages/Products/components/Subcategories/Subcategories.module.scss';

// typ danych wejściowych
interface IProps {
  categories?: ICategorySubcategoryListItem[];
}

const Subcategories: FC<IProps> = ({ categories }) => {
  return (
    <div className={styles.wrapperComponent}>
      <Grid
        container
        columnSpacing="16px"
        rowSpacing="30px"
        itemScope
        itemType="http://schema.org/ItemList">
        {categories?.map((category, i) => (
          <Grid key={i} item sm={6} md={3}>
            <Link to={`/${category.url_link}`}>
              <div className={styles.subcategory}>
                <div>{category.images.menu_icon && <img src={category.images.menu_icon} />}</div>
                <div className={styles.name}>{category.name}</div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Subcategories;
