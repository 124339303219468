// galeria zdjęć

import React, { FC, useState } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';

import { useRWD } from 'hooks';
import { IImage } from 'api/types';
import { Modal } from 'components/controls';
import GalleryModal from './components/GalleryModal';

import { SearchGalleryIcon } from 'assets/icons';

import styles from 'theme/pages/Product/components/Gallery/Gallery.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// typ danych wejściowych
interface IProps {
  images: IImage[];
  allowGalleryModal?: boolean;
  isProductList?: boolean;
}

const Gallery: FC<IProps> = ({ images, allowGalleryModal, isProductList }) => {
  const { isMobile } = useRWD();

  // index aktualnego zdjęcia (z tablicy zdjęć)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // czy jest włączona galeria na modalu
  const [isGalleryModal, setIsGalleryModal] = useState(false);

  const slidesToShow = images.length > 4 ? 4 : images.length;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    vertical: false
  };

  return (
    <>
      <div
        className={classnames(
          styles.wrapperComponent,
          'StylePath-Pages-Product-components-Gallery'
        )}>
        <div
          className={styles['main-image']}
          onClick={() => allowGalleryModal && setIsGalleryModal(true)}>
          <img
            src={isProductList ? images[currentImageIndex]?.min : images[currentImageIndex]?.main}
          />
          {allowGalleryModal && !isMobile && (
            <button className={styles.searchIcon}>
              <SearchGalleryIcon />
            </button>
          )}
        </div>

        <div className={styles.miniGallery} style={{ width: `${slidesToShow * (92 + 10)}px` }}>
          {images.length > 1 && (
            <Slider {...settings}>
              {images.map((image, index) => (
                <div
                  className={styles.thumb}
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}>
                  <img
                    src={isProductList ? image.thumb : image.thumb_watermarked}
                    className={classnames(styles.image, {
                      [styles.active]: index === currentImageIndex
                    })}
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
      {isGalleryModal && allowGalleryModal && (
        <Modal customWidth={1286} color="WHITE" title="" onClose={() => setIsGalleryModal(false)}>
          <GalleryModal images={images} initialSlide={currentImageIndex} />
        </Modal>
      )}
    </>
  );
};

export default Gallery;
