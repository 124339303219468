// Strona pojedynczego produktu

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import animateScrollTo from 'animated-scroll-to';
import { Grid, Tabs, Tab } from '@mui/material';

import { useRWD } from 'hooks';
import {
  useGetProduct,
  useGetProductSubelementsTypes,
  useGetProductSubelementItems,
  useGetProductBreadcrumbs,
  useGetProductCategoryCatalogs,
  useGetHomeSubelementItems
} from 'api';
import { IProductsBreadcrumbsRequest } from 'api/types';
import { reduxActions, useDispatch } from 'store';
import { Container, Breadcrumbs, Loader, Collapse } from 'components/controls';
import { ProductSlider, Search, AddToShoppingListButton } from 'components/containers';
import { Details, Prices, Gallery, AttributeList, Catalogs } from './components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from 'theme/pages/Product/Product.module.scss';

const Product = ({ id }: { id?: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile, isDesktop } = useRWD();

  // referencja do paska nawigacji
  const navWrapperRef = useRef<HTMLDivElement>(null);

  // referencja do sekcji pełny opis
  const fullDescriptionRef = useRef<HTMLDivElement>(null);

  // referencja do sekcji z technicznymi atrybutami
  const technicalAttributesRef = useRef<HTMLDivElement>(null);

  // referencja do sekcji z informacjami o dostawie
  const logisticAttributesRef = useRef<HTMLDivElement>(null);

  // referencja do sekcji z subelementami
  const subelementsRef = useRef<HTMLDivElement>(null);

  // ID produktu (przekształcony na int)
  const { id: paramId } = useParams();
  const productId = useMemo(() => parseInt(paramId || id || ''), [id]);

  // ID kategorii lub fraza wyszukiwania potrzebne jako param w breadcrumbs
  const { state } = useLocation();
  const { categoryId, searchKeywords } = (state || {}) as IProductsBreadcrumbsRequest;

  // Aktualnie wybrany tab (główna nawigacja)
  const [activeTab, setActiveTab] = useState<string | number>('id');

  // Aktualnie wybrany typ subelementu
  const [activeSubelementId, setActiveSubelementId] = useState<string | null>(null);

  // Lista rozwiniętych sekcji
  const [activeSections, setActiveSections] = useState<string[]>(['description_full']);

  // czy jest nawigacja w trakcie sticky
  const [isStickyNav, setIsStickyNav] = useState(false);

  // Pobranie produktu
  const {
    data: productData,
    isLoading: isProductLoading,
    refetch: refetchProductData
  } = useGetProduct(productId, {
    enabled: !!productId
  });

  // Pobranie typów subelementów
  const { data: productSubelementsTypesData } = useGetProductSubelementsTypes(
    productId,
    {
      page: 1,
      limit: 999
    },
    {
      enabled: !!productId,
      onSuccess: (data) => {
        // Ustawienie pierwszego typ subelementu jako wybrany
        setActiveSubelementId(data.items[0]?.id);
      }
    }
  );

  // Pobranie listy produktów po zmianie/wybraniu typu subelementu
  const { data: homeSubelementItemsData } = useGetHomeSubelementItems('RECOMMENDED', {
    page: 1,
    limit: 999
  });

  // Pobranie listy produktów po zmianie/wybraniu typu subelementu
  const {
    data: productSubelementItemsData,
    isLoading: isProductSubelementItemsLoading,
    isPreviousData: isPreviousDataProductSubelement
  } = useGetProductSubelementItems(
    productId,
    activeSubelementId || '',
    {
      page: 1,
      limit: 999
    },
    {
      enabled: !!productId && !!activeSubelementId,
      keepPreviousData: true
    }
  );

  // Pobranie listy breadcrumbs'ów danego produktu
  const { data: productBreadcrumbsData } = useGetProductBreadcrumbs(
    productId,
    {
      ...(categoryId ? { categoryId } : {}),
      ...(searchKeywords ? { searchKeywords } : {})
    },
    {
      enabled: !!productId
    }
  );

  const { data: productCatalogCategories } = useGetProductCategoryCatalogs(productId);

  // Ustawienie breadcrums'ów do wyświetlenia JCZ: w jakim momencie ? Po załadowaniu danych ?
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs(
        productBreadcrumbsData
          ? productBreadcrumbsData.items.map((item) => ({
              name: item.name,
              path: item.url_link ? `/${item.url_link}` : undefined
            }))
          : [
              {
                name: 'Produkty',
                path: '/products'
              },
              {
                name: productData?.title || 'Produkt'
              }
            ]
      )
    );
  }, [productData, productBreadcrumbsData]);

  // ustawianie informacji czy navigacyjny ma być sticky (przyklejony do headera)
  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsStickyNav(e.intersectionRatio < 1), {
      threshold: [1]
    });

    navWrapperRef.current && observer.observe(navWrapperRef.current);

    return () => {
      navWrapperRef.current && observer.unobserve(navWrapperRef.current);
    };
  }, [navWrapperRef.current]);

  // Zwinięcie/Rozwinięcie sekcji
  // Przy rozwinięciu zaznaczenie odpowiedniego tab'a w pasku nawigacji
  const toggleSection = (sectionProperty: string) => {
    activeSections.includes(sectionProperty)
      ? setActiveSections((prevState) => prevState.filter((item) => item !== sectionProperty))
      : (setActiveSections((prevState) => [...prevState, sectionProperty]),
        setActiveTab(sectionProperty));
  };

  // Elementy głównej nawigacji
  const navTabs: { property: string | number; label: string; collapse?: boolean; tab?: boolean }[] =
    useMemo(
      () => [
        ...(productCatalogCategories?.items.length
          ? [{ property: 'catalog', label: t('Katalogi'), collapse: true }]
          : []),
        ...(productData?.description_full
          ? [{ property: 'description_full', label: t('Pełny opis'), collapse: true }]
          : []),
        ...(productData?.technical_attributes.length
          ? [{ property: 'technical_attributes', label: t('Dane techniczne'), collapse: true }]
          : []),
        ...(productData?.logistic_attributes.length
          ? [{ property: 'logistic_attributes', label: t('Dane logistyczne'), collapse: true }]
          : []),
        ...(productSubelementsTypesData?.items.map((subelement) => ({
          property: subelement.id,
          label: subelement.name,
          tab: true
        })) || []),
        ...(homeSubelementItemsData?.items.length
          ? [{ property: 'recommended', label: t('Polecane'), tab: true }]
          : [])
      ],
      [productSubelementsTypesData, productCatalogCategories, homeSubelementItemsData]
    );

  // pobranie referencji do sekcji na podstawie nazwy pola w produkcie
  const getRefElement = (elementName: string) =>
    elementName === 'description_full'
      ? fullDescriptionRef
      : elementName === 'technical_attributes'
      ? technicalAttributesRef
      : elementName === 'logistic_attributes'
      ? logisticAttributesRef
      : elementName === 'recommended'
      ? subelementsRef
      : null;

  // funkcja odpowiedzialna za przeskrolowanie strony do odpowiedniej sekcji
  const scrollToElement = (elementName: string) => {
    const element = getRefElement(elementName)?.current;

    element &&
      animateScrollTo(element, {
        verticalOffset: -128
      });

    setActiveSections([...activeSections, elementName]);
  };

  const renderAsociation = () => {
    if (!productSubelementItemsData?.items.length && !homeSubelementItemsData?.items.length) {
      return null;
    }

    return (
      <div className={styles.productTabsWrapper} ref={subelementsRef}>
        <div className={styles.tabsBar}>
          <Container>
            <div className={styles.tabs}>
              {productSubelementsTypesData?.items.map((item) => (
                <div
                  key={item.id}
                  className={classnames(styles.tab, {
                    [styles.active]: item.id === activeSubelementId
                  })}
                  onClick={() => {
                    setActiveSubelementId(item.id);
                    setActiveTab(item.id);
                  }}>
                  {item.name}
                </div>
              ))}
              {homeSubelementItemsData?.items.length && (
                <div
                  className={classnames(styles.tab, {
                    [styles.active]: activeSubelementId === 'recommended'
                  })}
                  onClick={() => {
                    setActiveSubelementId('recommended');
                    setActiveTab('recommended');
                  }}>
                  {t('Polecane')}
                </div>
              )}
            </div>
          </Container>
        </div>
        <Container>
          <div className={styles.sliderWrapper}>
            {isProductSubelementItemsLoading && <Loader />}

            {productSubelementItemsData && productSubelementItemsData.items.length > 0 && (
              <ProductSlider
                products={productSubelementItemsData.items}
                itemsPerSlide={isMobile ? 1 : isDesktop ? 3 : 6}
                isLoading={isPreviousDataProductSubelement}
              />
            )}

            {homeSubelementItemsData &&
              homeSubelementItemsData.items.length > 0 &&
              (activeTab === 'recommended' ||
                !productSubelementItemsData ||
                productSubelementItemsData.items.length === 0) && (
                <ProductSlider
                  products={homeSubelementItemsData.items}
                  itemsPerSlide={isMobile ? 1 : isDesktop ? 3 : 6}
                />
              )}
          </div>
        </Container>
      </div>
    );
  };

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product')}
      itemScope
      itemType="http://schema.org/Product">
      {productData && (
        <Helmet>
          <title>{productData.title}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <div className={styles.searchWrapper}>
        <Search />
      </div>
      <Breadcrumbs />

      {isProductLoading && <Loader />}

      {productData && (
        <>
          <div className={styles.productDetailsWrapper}>
            <Container>
              <Grid container>
                <Grid item lg={8} md={12}>
                  <div className={styles.productDetails}>
                    <Gallery images={productData.images} allowGalleryModal />
                    <Details
                      product={productData}
                      scrollToDescription={() => {
                        setActiveTab('description_full');
                        setActiveSections([...activeSections, 'description_full']);
                        scrollToElement('description_full');
                      }}
                    />
                  </div>
                </Grid>

                <Grid item lg={4} md={12}>
                  <Prices product={productData} refetchProductData={refetchProductData} />
                </Grid>
              </Grid>
            </Container>
          </div>

          <div className={styles.productNavWrapper} ref={navWrapperRef}>
            <Container>
              <div className={styles.tabsWrapper}>
                <Tabs
                  value={activeTab}
                  onChange={(event, property) => {
                    setActiveTab(property);
                    typeof property === 'string' &&
                      (setActiveSections([...activeSections, property]), scrollToElement(property));
                  }}>
                  {navTabs.map((section) => (
                    <Tab
                      label={section.label}
                      key={section.property}
                      className={classnames('item', section.property === activeTab && 'active')}
                      value={section.property}
                    />
                  ))}
                </Tabs>
              </div>
            </Container>
          </div>

          <div className={styles.productExtendedWrapper}>
            {navTabs
              .filter((item) => item.collapse)
              .map((item) => (
                <div
                  ref={getRefElement(typeof item.property === 'string' ? item.property : '')}
                  key={item.property}>
                  <Collapse
                    open={activeSections.includes(item.property.toString())}
                    title={item.label}
                    onClick={() => toggleSection(item.property.toString())}>
                    {item.property === 'description_full' && (
                      <div
                        className={styles.fullDescription}
                        dangerouslySetInnerHTML={{ __html: productData.description_full }}
                      />
                    )}
                    {item.property === 'catalog' && (
                      <Catalogs catalogs={productCatalogCategories?.items || []} />
                    )}
                    {item.property === 'technical_attributes' && (
                      <AttributeList attributes={productData.technical_attributes} />
                    )}
                    {item.property === 'logistic_attributes' && (
                      <AttributeList attributes={productData.logistic_attributes} />
                    )}
                  </Collapse>
                </div>
              ))}
          </div>

          {renderAsociation()}
        </>
      )}
    </div>
  );
};

export default Product;
