// stopka głównego layoutu

import React from 'react';
import classnames from 'classnames';

import { useRWD } from 'hooks';
import { useGetHomeHtmlBlock } from 'api/endpoints';
import { Container } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/Footer/Footer.module.scss';

const Footer = () => {
  const { isMobile } = useRWD();

  const { data: footerData } = useGetHomeHtmlBlock('HOME_FOOTER', 'FOOTER');

  const renderContent = () => {
    const contentMobile = footerData?.items.find(
      (item) => item.section_field_symbol === 'CONTENT_MOBILE'
    );
    const contentDesktop = footerData?.items.find(
      (item) => item.section_field_symbol === 'CONTENT'
    );

    if (isMobile && contentMobile) {
      return <div dangerouslySetInnerHTML={{ __html: contentMobile?.html_code || '' }} />;
    }

    if (contentDesktop) {
      return <div dangerouslySetInnerHTML={{ __html: contentDesktop?.html_code || '' }} />;
    }

    return null;
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-Footer'
      )}
      itemScope
      itemType="http://schema.org/Organization">
      <meta itemProp="url" content={window.location.href} />
      <Container>
        <div className={styles.contentWrapper}>{renderContent()}</div>
      </Container>
    </div>
  );
};

export default Footer;
