// Hook odpowiedzialny za pobranie faktur

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// parametry requestu do api
export interface IRequest {
  exportType?: 'pdf' | 'xls' | 'csv';
}

// typ zwracanych danych
export interface IResponse {
  url: string;
  content: string;
  file_name: string;
}

const getDocumentsExport = (documentId: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/documents/export/${documentId}`, { params });

export const useGetDocumentsExport = (
  documentId: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['documents-export', documentId, params],
    () => getDocumentsExport(documentId, params),
    options
  );
