// dodatkowe filtry pod listą kategorii

import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { IProductsFilter, IProductsFilterValue } from 'api/types';
import { Select, Collapse } from 'components/controls';

import styles from 'theme/pages/Products/components/FiltersSidebar/FiltersSidebar.module.scss';

type IFilter = {
  filter_id: string;
  filter_value: string;
};

// typ danych wejściowych
interface IProps {
  filter: IProductsFilter;
  currentProductsFilters: IFilter[];
  setCurrentProductsFilters: Dispatch<SetStateAction<IFilter[]>>;
}

const FilterSidebar: FC<IProps> = ({
  filter,
  currentProductsFilters,
  setCurrentProductsFilters
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  return (
    <Collapse title={filter.label} open={open} onClick={() => setOpen(!open)} isFilter>
      <div className={styles.filter} key={filter.id}>
        {filter.type === 'singlechoice' ? (
          <Select<IProductsFilterValue>
            value={
              currentProductsFilters.find((item) => item.filter_id === filter.id)?.filter_value
            }
            onChange={(item) =>
              setCurrentProductsFilters((prevState) => [
                ...prevState.filter((assignedFilter) => assignedFilter.filter_id !== filter.id),
                ...(item ? [{ filter_id: filter.id, filter_value: item.value }] : [])
              ])
            }
            options={filter.values.map((item) => ({
              value: item.value,
              label: item.name,
              item
            }))}
            placeholder={filter.label}
          />
        ) : (
          <div className={styles.unknownType}>
            {t('Nieznany typ filtra')} (<strong>{filter.type}</strong>)
          </div>
        )}
      </div>
    </Collapse>
  );
};

export default FilterSidebar;
