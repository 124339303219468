// Hook odpowiedzialny za pobranie listy produktow

import axios from 'api/axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse,
  IProductListItem
} from 'api/types';

// parametry requestu do api
type IRequest = IPaginationRequest;

// typ zwracanych danych
type IResponse = IPaginationResponse<IProductListItem>;

const getHomeSubelementItems = (subelementType: string, params?: IRequest): Promise<IResponse> =>
  axios.get(`/home/subelements/${subelementType}`, { params });

export const useGetHomeSubelementsInfiniteList = (
  subelementType: string,
  params?: IRequest,
  options?: UseInfiniteQueryOptions<IResponse, IError>
) =>
  useInfiniteQuery<IResponse, IError>(
    [`home-subelements-${subelementType}-infinite`, params],
    ({ pageParam = params?.page }) =>
      getHomeSubelementItems(subelementType, { ...params, page: pageParam }),
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.page + 1,
      getPreviousPageParam: (lastPage) => lastPage.page - 1
    }
  );
