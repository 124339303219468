// slider produktów

import React, { FC } from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import classnames from 'classnames';

import { useRWD } from 'hooks';
import { IProductListItem } from 'api/types';
import { ProductItem } from 'components/containers';

import { AngleDownIcon } from 'assets/icons';

import styles from 'theme/components/containers/ProductSlider/ProductSlider.module.scss';

// typ danych wejściowych
interface IProps {
  products: IProductListItem[];
  itemsPerSlide: number;
  isArrowHidden?: boolean;
  isLoading?: boolean;
}

const ProductSlider: FC<IProps> = ({ products, itemsPerSlide, isArrowHidden, isLoading }) => {
  const { isMobile } = useRWD();

  const hideArrows = isArrowHidden || isMobile;

  const renderContent = () => {
    return (
      <CarouselProvider
        naturalSlideWidth={0}
        naturalSlideHeight={0}
        totalSlides={products.length}
        visibleSlides={itemsPerSlide}
        disableKeyboard
        dragEnabled={false}>
        {products.length + 1 > itemsPerSlide && (
          <>
            <ButtonBack disabled={hideArrows} className={classnames(styles.navButton, styles.back)}>
              <AngleDownIcon />
            </ButtonBack>
            <ButtonNext disabled={hideArrows} className={classnames(styles.navButton, styles.next)}>
              <AngleDownIcon />
            </ButtonNext>
          </>
        )}

        <Slider>
          {products.map((product, index) => (
            <Slide key={product.id} index={index} className={styles.slide}>
              <div className={styles.productWrapper}>
                <ProductItem key={product.id} product={product} slide />
              </div>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.isLoading]: !!isLoading },
        'StylePath-Components-Containers-ProductSlider'
      )}>
      {renderContent()}
    </div>
  );
};

export default ProductSlider;
