// dodatkowe filtry pod listą kategorii

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IProductsFilter } from 'api/types';
import { Button } from 'components/controls';

import FilterSidebar from './components/FilterSidebar';

import { SortDownIcon } from 'assets/icons';

import styles from 'theme/pages/Products/components/FiltersSidebar/FiltersSidebar.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
}

// typ danych wejściowych
interface IProps {
  categoryId?: number;
  queryFilters: IFilter[];
  filtersData?: IProductsFilter[];
  onChange: (filters: IFilter[]) => void;
}

const FiltersSidebar: FC<IProps> = ({ categoryId, onChange, queryFilters, filtersData }) => {
  const { t } = useTranslation();

  // lista zqaznaczonych filtrów
  const [currentProductsFilters, setCurrentProductsFilters] = useState<
    { filter_id: string; filter_value: string }[]
  >([]);

  // nadpisanie lokalnych filtrów przy zmienie filtrów z props'ów
  useEffect(() => {
    setCurrentProductsFilters(queryFilters);
  }, [queryFilters]);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Products-components-FiltersSidebar'
      )}>
      {(!categoryId || (filtersData?.length || 0) > 0) && (
        <div className={styles.title}>{t('Filtry')}</div>
      )}

      {(filtersData?.length || 0) > 0 && (
        <div className={styles.filtersWrapper}>
          {filtersData?.map((filter, i) => (
            <FilterSidebar
              filter={filter}
              currentProductsFilters={currentProductsFilters}
              setCurrentProductsFilters={setCurrentProductsFilters}
              key={i}
            />
          ))}

          <div className={styles.actions}>
            <Button ghost color="secondary" onClick={() => onChange(currentProductsFilters)}>
              {t('Filtruj')} <SortDownIcon />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersSidebar;
