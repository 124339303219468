// pliki z tłumaczeniami

import enTranslations from './en.json';
import plTranslations from './pl.json';
import ruTranslations from './ru.json';

export const resources = {
  en: {
    translation: enTranslations
  },
  pl: {
    translation: plTranslations
  },
  ru: {
    translation: ruTranslations
  }
};
