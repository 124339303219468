// przycisk z listą podglądu kkoszyków

import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import { Trans } from 'react-i18next';
import { ChevronDown, X } from 'react-bootstrap-icons';

import { useRWD, useAppNavigate } from 'hooks';
import { reduxActions, useDispatch, useSelector } from 'store';
import { useGetCartsAll } from 'api';
import { Button } from 'components/controls';

import { ClearCart } from './components';

import { CartIcon } from 'assets/icons';

import styles from 'theme/components/containers/CartsButton/CartsButton.module.scss';

const ButtonCart = () => {
  const navigate = useAppNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();

  // czy jest ustawiony element HTML popovera (użyte przy pokazywaniu modala)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);

  // ID domyślnego koszyka
  const { currentCartId } = useSelector((state) => state.cart);

  // pobranie listy koszyków
  const {
    data: cartsData,
    refetch: refetchCartsData,
    isFetching: isCartsFetching
  } = useGetCartsAll({
    onSuccess: (data) => {
      !currentCartId && dispatch(reduxActions.setCurrentCartId(data.items[0]?.id) || null);
    }
  });

  // domyślny koszyk
  const currentCart = useMemo(
    () => cartsData?.items.find((cart) => currentCartId === cart.id),
    [currentCartId, cartsData]
  );

  // pobieranie danych przy otwarciu popovera
  useEffect(() => {
    if (currentCart?.id) {
      refetchCartsData();
    }
  }, [currentCart?.id]);

  // ustawienie domyślnego koszyka po pobraniu/aktualizacji listy koszyków
  useEffect(() => {
    !isCartsFetching &&
      !currentCart &&
      dispatch(reduxActions.setCurrentCartId(cartsData?.items[0]?.id || null));
  }, [isCartsFetching, currentCart, cartsData]);

  useEffect(() => {
    if (currentCartId) {
      dispatch(reduxActions.setCurrentCartId(currentCartId));
    }
  }, [currentCartId]);

  const handleButtonClick = (id: number) => {
    if (id !== currentCartId) {
      dispatch(reduxActions.setCurrentCartId(id));

      return;
    }

    navigate(`/cart/${currentCartId}`);
    setPopoverAnchor(null);
  };

  const renderFreeDeliveryContent = () => {
    if (currentCart?.has_free_delivery) {
      return (
        <div className={styles.freeDelivery}>
          <Trans>Darmowa dostawa!</Trans>
        </div>
      );
    }

    return (
      <div className={classnames(styles.freeDelivery, styles.freeDeliveryMissing)}>
        <span>
          <Trans>Do darmowej dostawy brakuje Ci</Trans>{' '}
          {currentCart?.amount_to_free_delivery_formatted} {currentCart?.currency}
        </span>
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-CartsButton'
      )}>
      <Badge
        color="secondary"
        badgeContent={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <div
          className={classnames(styles.button, { [styles.active]: popoverAnchor })}
          onClick={(event) => setPopoverAnchor(event.currentTarget)}>
          {isMobile ? (
            <div>
              <CartIcon className={styles.icon} />
            </div>
          ) : (
            <>
              <CartIcon className={styles.icon} />

              {currentCart && (
                <div className={styles.detailsCart}>
                  <div className={styles.countCarts}>{currentCart.name}</div>

                  <div className={styles.price}>
                    {currentCart.positions_value_net_formatted} {currentCart.currency}
                  </div>
                </div>
              )}

              <ChevronDown className={classnames('arrow', { rotated: !!popoverAnchor })} />
            </>
          )}
        </div>
        {!isMobile &&
          !popoverAnchor &&
          currentCart?.amount_to_free_delivery_formatted &&
          renderFreeDeliveryContent()}
      </Badge>

      <Popover
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <div className={styles.cartWrapper}>
          <div className={styles.cartHeader}>
            <span>
              <Trans>Wybierz koszyk</Trans>
            </span>
            <X size={30} onClick={() => setPopoverAnchor(null)} />
          </div>
          <div className={styles.cartBody}>
            {cartsData?.items.map((cart, i) => (
              <div className={styles.cartTile} key={i}>
                <div className={styles.tileHeading}>{cart.name}</div>
                <div className={styles.tileBody}>
                  <div className={styles.qty}>
                    <span>
                      <Trans>Ilość</Trans>:
                    </span>{' '}
                    <span>{cart.products_count}</span>
                  </div>
                  <div>
                    <div className={classnames(styles.price, styles.priceNet)}>
                      <span>{cart.positions_value_net_formatted.replace('.', ',')}</span>{' '}
                      <Trans>netto</Trans>
                    </div>
                    <div className={classnames(styles.price, styles.priceGross)}>
                      {cart.positions_value_gross_formatted.replace('.', ',')} <Trans>brutto</Trans>
                    </div>
                  </div>
                </div>
                <div className={styles.tileFooter}>
                  <ClearCart cartId={cart.id} refetchCartsData={() => refetchCartsData()} />
                  <Button
                    color={currentCartId === cart.id ? 'primary' : 'secondary'}
                    ghost={currentCartId !== cart.id}
                    onClick={() => handleButtonClick(cart.id)}>
                    {currentCartId === cart.id ? (
                      <Trans>Przejdź do koszyka</Trans>
                    ) : (
                      <Trans>Wybierz</Trans>
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ButtonCart;
