import React, { FC, PropsWithChildren } from 'react';

import { useGetDocumentsExport } from 'api';
import { useNotifications } from 'hooks';

import styles from 'theme/pages/Cart/components/ActionBar/ActionBar.module.scss';

interface IProps extends PropsWithChildren {
  fileType: 'pdf' | 'xls' | 'csv';
  id: number;
}

const ExportButton: FC<IProps> = ({ fileType, id, children }) => {
  const { showErrorMessage } = useNotifications();

  // eksport koszyka do PDF
  const { refetch: downloadPdf } = useGetDocumentsExport(
    id,
    {
      exportType: fileType
    },
    {
      enabled: false,
      onSuccess: (data) => {
        fetch(data.url)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = data.file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => showErrorMessage('Błąd pobierania pliku'));
      }
    }
  );

  return (
    <div className={styles.cartActionsItemLabel} onClick={() => downloadPdf()}>
      {children}
    </div>
  );
};

export default ExportButton;
